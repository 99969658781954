import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/seo'

import Hero from '../components/Hero'

const pricingPage = () => (
  <Layout>
    <Seo title="Pricing | Talk of the Town" />

    <Hero text="Pricing" />

  </Layout>
)

export default pricingPage
